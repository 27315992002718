import {
  forwardRef,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  TouchEventHandler,
} from "react";
import styles from "./InputSlider.module.css";

interface InputSliderProps {
  className?: string;
  sliderClassName?: string;
  disable?: boolean;
  min?: number;
  max?: number;
  step?: number;
  values?: [number, number];
  onChange?: (values: [number, number]) => void;
  onMouseUp?: (values: [number, number]) => void;
}

export const InputSlider = forwardRef<HTMLInputElement, InputSliderProps>(
  (props, _ref) => {
    const {
      className,
      sliderClassName,
      min = 0,
      max = 10,
      step = 1,
      values = [min, max],
      disable,
      onChange,
      onMouseUp,
      ...extraProps
    } = props;

    const [leftValue, rightValue] = values;
    const calcPercent = useCallback(
      (value: number) => ((value - min) / (max - min)) * 100,
      [min, max]
    );

    // TODO: useReducerを使ってリファクタする
    const [inputLeftValue, setInputLeftValue] = useState(leftValue);
    const [inputRightValue, setInputRightValue] = useState(rightValue);
    const [leftPercent, setLeftPercent] = useState(calcPercent(leftValue));
    const [rightPercent, setRightPercent] = useState(calcPercent(rightValue));
    const [isLeftMoving, setIsLeftMoving] = useState(false);
    const [isRightMoving, setIsRightMoving] = useState(false);

    const calculatePosition = (value: number) =>
      (100 / (max - min)) * (value - min);

    const setLeftValue = (e: ChangeEvent<HTMLInputElement>) => {
      setIsLeftMoving(true);
      const newValue = Math.min(
        parseInt(e.target.value),
        inputRightValue - step
      );
      const percent = calculatePosition(newValue);

      setInputLeftValue(newValue);
      setLeftPercent(percent);

      onChange?.([newValue, inputRightValue]);
    };

    const setRightValue = (e: ChangeEvent<HTMLInputElement>) => {
      setIsRightMoving(true);
      const newValue = Math.max(
        parseInt(e.target.value),
        inputLeftValue + step
      );
      const percent = calculatePosition(newValue);

      setInputRightValue(newValue);
      setRightPercent(percent);

      onChange?.([inputLeftValue, newValue]);
    };

    const handleMouseDownLeft = () => {
      setIsLeftMoving(true);
    };
    const handleMouseDownRight = () => {
      setIsRightMoving(true);
    };

    const handleMouseUp = () => {
      setIsLeftMoving(false);
      setIsRightMoving(false);
      onMouseUp?.([inputLeftValue, inputRightValue]);
    };

    const handleTouchend: TouchEventHandler = () => {
      setIsLeftMoving(false);
      setIsRightMoving(false);
      onMouseUp?.([inputLeftValue, inputRightValue]);
    };

    useEffect(() => {
      setInputLeftValue(leftValue);
      setInputRightValue(rightValue);
      setLeftPercent(calcPercent(leftValue));
      setRightPercent(calcPercent(rightValue));
    }, [leftValue, rightValue, calcPercent]);

    const rootClassName = [
      className,
      styles.root,
      isLeftMoving || isRightMoving ? styles.rootActive : "",
    ].join(" ");

    return (
      <div className={rootClassName} {...extraProps}>
        <input
          className={styles.input}
          disabled={disable}
          onChange={setLeftValue}
          onMouseUp={handleMouseUp}
          onMouseDown={handleMouseDownLeft}
          onTouchEnd={handleTouchend}
          type="range"
          min={min}
          max={max}
          step={step}
          value={inputLeftValue}
        />
        <input
          className={styles.input}
          disabled={disable}
          onChange={setRightValue}
          onMouseUp={handleMouseUp}
          onMouseDown={handleMouseDownRight}
          onTouchEnd={handleTouchend}
          type="range"
          min={min}
          max={max}
          step={step}
          value={inputRightValue}
        />
        {/** 自前実装したスライダーを被せる */}
        <div className={[styles.slider, sliderClassName].join(" ")}>
          <div className={styles.track} />
          <div
            className={styles.range}
            style={{
              left: `${leftPercent}%`,
              right: `${100 - rightPercent}%`,
            }}
          />
          <div
            className={[
              styles.thumb,
              styles.left,
              isLeftMoving ? styles.active : "",
            ].join(" ")}
            style={{
              left: `${leftPercent}%`,
            }}
          />
          <div
            className={[
              styles.thumb,
              styles.right,
              isRightMoving ? styles.active : "",
            ].join(" ")}
            style={{ right: `${100 - rightPercent}%` }}
          />
        </div>
      </div>
    );
  }
);

InputSlider.displayName = "InputSlider";
