import { BrowserOptions } from "@sentry/nextjs";

const IGNORE_TRANSACTION_PATH = [
  // NEWT-WEB-D7Y
  "/webflow",
];

const IGNORE_EXCEPTION_VALUES = [
  // https://github.com/vercel/next.js/issues/43088
  "Invariant: attempted to hard navigate to the same URL",
  // NEWT-WEB-2
  "Non-Error promise rejection captured with value: Timeout",
  // NEWT-WEB-G
  "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again",
  // NEWT-WEB-DN
  "AbortError: AbortError",
  // NEWT-WEB-D7Y
  "Cannot read properties of undefined (reading 'top')",
  // NEWT-WEB-D8H
  "Right side of assignment cannot be destructured",
];

const IGNORE_MODULES = [
  // NEWT-WEB-16H
  // "@sentry/utils/esm/instrument",
  // NEWT-WEB-BJW
  "app:///gtag/js",
  // NEWT-WEB-BZF
  "app:///gtm.js?id=GTM-TSR6QX9",
  // NEWT-WEB-BHQ
  "app:///ajax/libs/jquery",
  // NEWT-WEB-BZ3
  "@firebase/auth/dist/esm2017/index",
  // NEWT-WEB-BVS
  "app:///en_US/fbevents.js",
] as string[];

const IGNORE_FILENAMES = [
  // NEWT-WEB-D7Y
  "app:///sale",
];

export const beforeSend: BrowserOptions["beforeSend"] = (event) => {
  /** filter with transaction name *****************/
  for (const path of IGNORE_TRANSACTION_PATH) {
    if (event?.transaction?.match(path)) {
      return null;
    }
  }

  /** filter with exception ************************/
  const firstValue = event?.exception?.values?.[0];
  const framesOfFirstValue = firstValue?.stacktrace?.frames || [];

  // filter with exception value
  for (const value of IGNORE_EXCEPTION_VALUES) {
    if (firstValue?.value?.match(value)) {
      return null;
    }
  }

  // filter with first module name
  for (const ignoreModule of IGNORE_MODULES) {
    if (framesOfFirstValue[0]?.module?.match(ignoreModule)) {
      return null;
    }
  }

  // filter with file name
  for (const ignoreFilename of IGNORE_FILENAMES) {
    // どこかしらで該当ファイルを経由していたらスキップする
    for (const frame of framesOfFirstValue) {
      if (frame?.filename?.match(ignoreFilename)) {
        return null;
      }
    }
  }

  // filter with function name  // NEWT-WEB-342
  if (framesOfFirstValue[0]?.function?.match("doubleclick.net")) {
    return null;
  }

  return event;
};
