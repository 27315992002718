import { FC, memo } from "react";
import styles from "./Histogram.module.css";

interface HistogramItemProps {
  isActive: boolean;
  ratio: number;
}

const HistogramItem: FC<HistogramItemProps> = ({ isActive, ratio }) => {
  return ratio === 0 ? (
    <div className={styles.item}></div>
  ) : (
    <div
      className={[styles.item, isActive ? styles.active : ""].join(" ")}
      style={{ height: `calc(100% * ${ratio})`, minHeight: 4 }}
    ></div>
  );
};

interface HistogramProps {
  items: Readonly<{ ratio: number }[]>;
  fromIndex: number;
  toIndex: number;
}

const Histogram: FC<HistogramProps> = (props) => {
  const { items, fromIndex, toIndex } = props;

  return (
    <div className={styles.root}>
      {items.map((item, index) => {
        const isActive = index >= fromIndex && index <= toIndex;
        return (
          <HistogramItem key={index} isActive={isActive} ratio={item.ratio} />
        );
      })}
    </div>
  );
};

export const MemorizedHistogram = memo(Histogram);
