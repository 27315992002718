import { BrowserOptions } from "@sentry/nextjs";

const IGNORE_MESSAGES = [
  "clarity.ms/collect",
  "braze",
  "Error: Minified React error",
  "TypeError: Right side of assignment cannot be destructured",
  "pagead2.googlesyndication.com",
  // NEWT-WEB-D7Y
  "TypeError: Cannot read properties of undefined (reading 'top')",
];

export const beforeBreadcrumb: BrowserOptions["beforeBreadcrumb"] = (
  breadcrumb,
  hint
) => {
  const message = breadcrumb?.message;

  for (const ignoreMessage of IGNORE_MESSAGES) {
    if (message?.match(ignoreMessage)) {
      return null;
    }
  }

  if (breadcrumb.category === "xhr" && hint?.xhr) {
    if (hint?.xhr?.responseURL?.match(/clarity.ms/)) {
      return null;
    }
    return breadcrumb;
  }

  return breadcrumb;
};
