;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"8af382c493f2d90befacd503fdf59cfd686a06af"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import {
  ignoreErrors,
  denyUrls,
  beforeSend,
  beforeBreadcrumb,
} from "@newt/config/sentry";
import { APP_ENV, USE_SENTRY } from "./src/constants";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: APP_ENV,
  tracesSampleRate: 0.05,
  enabled: USE_SENTRY,
  // This sets the sample rate. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,
  integrations: [Sentry.replayIntegration()],
  ignoreErrors,
  denyUrls,
  beforeSend,
  beforeBreadcrumb,
});
